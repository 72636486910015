require("./../../../bower_components/jquery/dist/jquery.js");
global.jQuery = require("./../../../bower_components/jquery/dist/jquery.js");
global.$ = require("./../../../bower_components/jquery/dist/jquery.js");
/*
================================================================================
* javascript information
* file name  : main.js
================================================================================
*/
$(document).ready(function(){

	$("#menu_btn").on("click", function(){
		$(".sidebar_content").toggleClass("active");
	});
  $(".close_btn").on("click", function(){
		$(".sidebar_content").toggleClass("active");
	});

});

$(function() {
  $(".nav_block").hover(
    function(){
      var h = $(this).find("ul").data("height");
      $(this).find("ul").css("height",h);
    },
    function(){
      $(this).find("ul").css("height",0);
    }
  );
});
$(function() {
	selecter = $('.page_top');
		//画面をスクロールした時に実行
	var footer_offset = $('footer').offset().top;
	var w_height = $("body").height();
		$(window).scroll(function () {
		 //200pxスクロールした時に実行する
		 if ($(this).scrollTop() > 200) {
		     selecter.addClass("active");
				 if($(this).scrollTop() > footer_offset - 240) {
				     selecter.addClass("footer");
				 } else {
				     selecter.removeClass("footer");
				 }
		 }
		 else {
				 selecter.removeClass("active");
		 }
	});
	selecter.click(function () {
			$('body,html').animate({
					scrollTop: 0
			}, 500); //0.5秒かけてトップへ移動
			return false;
	});
});
